import * as VueRouter from 'vue-router'

import Home from '@/pages/Home';

const routes = [
  { path: '/',
    name: 'Home',
    component: Home
  },
  { path: '/about',
    name: 'About',
    component: () => import('@/pages/About')
  },
  { path: '/gallery',
    name: 'Gallery',
    component: () => import('@/pages/Gallery')
  },
  { path: '/resume',
    name: 'Resume',
    component: () => import('@/pages/Resume')
  },
  { path: '/reel',
    name: 'Reel',
    beforeEnter: () => {
      window.location.replace('https://vimeo.com/800698391?share=copy')
    }
  },
  { path: '/:pathMatch(.*)*',
    redirect: '/404'
  },
  { path: '/404',
    name: '404',
    component: () => import('@/pages/404')
  }
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  scrollBehavior() {
    return { top: 0 }
  },
  routes,
});

export default router;
<template>
  <div class="social-links" :class="size">
    <div v-for="(link, idx) in socialLinks"
         :key="`${link.href}-${idx}`"
         class="social-link-wrapper"
         :class="wrapperClass"
         :style="wrapperStyle">
        <a :href="link.href"
           class="social-link"
           :class="`${link.icon} ${linkClass || ''}`"
           :style="linkStyle"
           target="_blank"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SocialLinks',

    props: {
      size: {
        type: String,
        default: 'md'
      },
      wrapperClass: String,
      wrapperStyle: String,
      linkClass: String,
      linkStyle: String
    },

    data() {
      return {
        socialLinks: [
          {
            href: 'https://instagram.com/adamchaarawi',
            icon: 'fa fa-instagram'
          },
          {
            href: 'https://www.imdb.com/name/nm13719421/',
            icon: 'fa fa-imdb'
          }
        ]
      }
    }
  }
</script>

<style scoped>
  .social-links {
    display: flex;
    flex-direction: row;
    width: fit-content;
    /*font-size: 1.6rem;*/
  }

  .social-link {
    background: transparent;
  }

  .social-links.sm .social-link {
    font-size: 1.25rem;
  }

  .social-links.sm .social-link-wrapper:not(:first-child) .social-link {
    margin-left: 20px;
  }

  .social-links.md .social-link {
    font-size: 1.4rem;
  }

  .social-links.md .social-link-wrapper:not(:first-child) .social-link {
    margin-left: 25px;
  }
</style>
<template>
  <div class="content-section"
       :class="{transparent}">
    <div class="content-wrapper">
      <div class="content">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ContentSection',

    props: {
      transparent: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>

<style>
  .content-section {
    background: hsla( 146.08695652173913,37.704918032786885%,11.960784313725489% ,1);
    color: white;
  }

  .content-section.transparent {
    background: transparent !important;
    color: inherit;
  }

  .content-wrapper {
    display: flex;
    justify-content: center;
    padding: 50px 0;
    box-sizing: content-box;
    margin: 0 auto;
  }

  .content {
    width: 100%;
  }

  .content > :not(:last-child) {
    padding-bottom: 30px;
  }

  .content div {
    margin-left: auto !important;
    margin-right: auto !important;
  }
</style>
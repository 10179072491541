<template>
  <div>
    <div id="section-background">
      <img id="background-img"
           :src="backgroundImg"/>
    </div>

    <ContentSection>
      <h1 style="margin: 0;"> la & nyc based actor </h1>

      <div style="display: flex; justify-content: center">
        <RouterButton to="about"
                      label="about me"
                      style="font-size: 1.15rem"/>
      </div>
    </ContentSection>
  </div>
</template>

<script>
  import ContentSection from '@/components/ContentSection';
  import RouterButton from '@/components/RouterButton';

  export default {
    name: 'Home',

    components: { RouterButton, ContentSection },

    // head: {
    //   title: 'Adam Chaarawi',
    //   meta: [
    //     {
    //       name: 'description',
    //       id: 'desc',
    //       content: "I'm Adam Chaarawi! I'm a New York and Los Angeles based actor. Check out my site :)",
    //     }
    //   ],
    // },

    data() {
      return {
        backgroundImg: 'https://res.cloudinary.com/dt7fbptp2/image/upload/v1703786732/personal/_RJ_0070_vrf2tr.jpg',
        // backgroundImg: require('@/assets/_RJ_0070.jpg')
      }
    }
  }
</script>

<style scoped>
  #section-background {
    height: 100vh;
    width: 100vw;
  }

  #background-img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
</style>
<template>
  <footer id="app-footer">
    <ContentSection transparent>
      <SocialLinks/>

      <div id="copyright-tag">
        <span>
          © {{ currentYear }} Adam Chaarawi
        </span>
      </div>
    </ContentSection>
  </footer>
</template>

<script>
  import ContentSection from '@/components/ContentSection';
  import SocialLinks from '@/components/SocialLinks';

  export default {
    name: 'Footer',

    components: { SocialLinks, ContentSection },

    data() {
      return {
        currentYear: new Date().getFullYear()
      }
    }
  }
</script>

<style scoped>
  #app-footer {
    width: 100vw;
    background: white;
    color: black;
    padding: 1.875em 0;
  }

  #copyright-tag {
    font-size: 1.2rem;
    color: black;
    padding-top: 10px;
  }
</style>
<template>
  <router-link :to="to"
               class="router-btn">
    {{ label }}
  </router-link>
</template>

<script>
  export default {
    name: 'RouterButton',

    props: {
      to: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>
  .router-btn {
    padding: 1em 2em;
    border: solid 1px white;
    font-size: 1em;
    background: transparent;
    color: white;
    transition: all .1s ease-in;
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    -webkit-transition: all .1s ease-in;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  .router-btn:hover {
    background: white;
    color: black;
  }
</style>
<template>
  <Header/>

  <main id="page">
    <div id="content" :class="{home: $route && $route.path === '/'}">
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </div>
  </main>

  <Footer/>
</template>

<script>
  import Footer from '@/components/Footer';
  import Header from '@/components/Header';

  export default {
    name: 'App',

    // head: {
    //   title: 'Adam Chaarawi',
    //   meta: [
    //     {
    //       name: 'description',
    //       id: 'desc',
    //       content: "I'm Adam Chaarawi! I'm a New York and Los Angeles based actor. Check out my site :)",
    //     }
    //   ],
    // },

    components: {
      Footer,
      Header
    }
  }
</script>

<style>
  @import "app.css";

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  html, body {
    font-family: 'Karla', sans-serif;
    margin: 0;
    font-size: 16px;
  }

  #app {
    font-family: 'Karla', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100vh;
  }

  #page {
    width: 100%;
    box-sizing: border-box;
    pointer-events: auto;
    min-height: calc(100vh - 7em - 15.5em);
  }

  #content {
    max-width: 1340px;
    margin-left: auto;
    margin-right: auto;
    padding: 8em 2.5em 0 2.5em;
  }

  #content.home {
    max-width: unset !important;
    padding: 0 !important;
  }

  @media only screen and (max-width: 500px) {
    html, body {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 400px) {
    html, body {
      font-size: 12px;
    }
  }
</style>

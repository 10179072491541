<template>
  <div class="menu-btn"
       :class="{open}"
       tabindex="0"
       @click="onClick">
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
  </div>
</template>

<script>
  export default {
    name: 'MenuButton',

    props: ['open'],

    methods: {
      onClick() {
        this.$emit('update:open', !this.open);
      }
    }
  }
</script>

<style scoped>
  .menu-btn {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    border-radius: 0;
    cursor: pointer;
    pointer-events: auto;
    margin: auto 0 auto 25px;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: opacity .2s;
  }

  .menu-btn:hover {
    opacity: 0.5;
  }

  .icon-bar {
    display: block;
    width: 25px;
    height: 1px;
    background: black;
    transition: .3s;
    transform-origin: right;
    opacity: 1;
  }

  .icon-bar:nth-child(2) {
    margin: 7.5px 0;
  }

  .menu-btn.open .icon-bar:nth-of-type(1) {
    transform: rotate(-45deg);
  }
  .menu-btn.open .icon-bar:nth-of-type(2) {
    opacity: 0;
  }

  .menu-btn.open .icon-bar:nth-of-type(3) {
    transform: rotate(45deg);
  }
</style>
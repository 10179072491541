<template>
  <header id="header"
          :class="{home: $route && $route.path === '/', 'menu-open': headerMenuOpen}">
    <div id="header-wrapper">
      <div id="header-inner">
        <div id="header-title-wrapper">
          <div id="header-title">
            <router-link
              id="header-title-label"
               to="/"
               data-animation-role="header-element"
              @click="() => this.headerMenuOpen = false">
              Adam Chaarawi
            </router-link>

<!--            <div id="header-subtitle">-->
<!--              <span> Actor </span>-->
<!--            </div>-->
          </div>

          <div id="header-nav">
            <nav id="header-nav-list">
              <div v-for="(tab, index) in tabs"
                   :key="index"
                   class="header-nav-item">
                <router-link v-if="!!tab.path"
                             :to="tab.path"
                             data-animation-role="header-element"
                             aria-current="page"
                             :class="{'current-nav': $route && $route.path === tab.path}">
                  {{ tab.label }}
                </router-link>

                <a v-else
                   :href="tab.redirect"
                   target="_blank">
                  {{ tab.label }}
                </a>
              </div>
            </nav>
          </div>

          <div id="header-menu-btn">
            <MenuButton v-model:open="headerMenuOpen"/>
          </div>
        </div>

        <div id="header-actions-wrapper">
          <SocialLinks id="header-actions"
                       size="sm"/>
        </div>
      </div>
    </div>

    <transition name="fade"
                mode="in-out">
      <div v-if="headerMenuOpen && windowWidth <= widthThreshold"
           id="header-menu">
        <nav id="header-menu-nav-list">
          <div id="header-menu-nav-folder">
            <div id="header-menu-nav-folder-content">
              <div id="header-menu-nav-folder-wrapper">
                <div id="header-menu-nav-wrapper">
                  <div v-for="(tab, index) in tabs"
                       :key="index"
                       class="header-menu-nav-item">

                    <router-link v-if="!!tab.path"
                                 :to="tab.path"
                                 data-animation-role="header-element"
                                 aria-current="page"
                                 :class="{'current-nav': $route && $route.path === tab.path}"
                                 @click="() => this.headerMenuOpen = false">
                      {{ tab.label }}
                    </router-link>

                    <a v-else
                       :href="tab.redirect"
                       target="_blank">
                      {{ tab.label }}
                    </a>
                  </div>

                </div>
              </div>
            </div>

            <div id="header-menu-actions">
              <SocialLinks size="md"/>
            </div>
          </div>
        </nav>
      </div>
    </transition>
  <!--                  style="transition-timing-function: ease; transition-duration: 1.5s; transition-delay: 0.0521739s;"-->
  </header>
</template>

<script>
  import MenuButton from '@/components/MenuButton';
  import SocialLinks from '@/components/SocialLinks';

  export default {
    name: 'Header',

    components: { SocialLinks, MenuButton },

    data () {
      return {
        headerMenuOpen: false,
        windowWidth: window.innerWidth,
        widthThreshold: 800,
        tabs: [
          {
            label: 'about',
            path: '/about'
          },
          {
            label: 'resume',
            path: '/resume'
          },
          {
            label: 'gallery',
            path: '/gallery'
          },
          {
            label: 'reel',
            redirect: 'https://vimeo.com/800698391?share=copy'
          }
        ]
      }
    },

    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
    },

    beforeUnmount() {
      window.removeEventListener('resize', this.onResize);
    },

    watch: {
      windowWidth(width) {
        if (width > this.widthThreshold) {
          this.headerMenuOpen = false;
        }
      },

      headerMenuOpen(open) {
        document.getElementsByTagName('body')[0].style = open ? 'overflow: hidden' : 'overflow: auto';
      }
    },

    methods: {
      onResize() {
        this.windowWidth = window.innerWidth;
      },
    }
  }
</script>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  #header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: black;
    overflow: hidden;
    position: absolute;
    width: 100%;
  }

  #header.home {
    color: white;
  }

  #header.menu-open #header-title {
    color: black !important;
  }

  #header-wrapper {
    position: relative;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    padding: 2.5em 2.5em;
    pointer-events: auto;
  }

  #header-inner {
    display: flex;
    width: 100%;
    max-width: 1340px;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }

  #header-title-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex: 1 0 67%;
    align-items: center;
  }

  #header-title {
    width: 33%;
    flex: 1 1 33%;
    text-align: left;
  }

  #header-subtitle {
    position: absolute;
    font-size: 0.7rem;
    color: #aaa;
    font-weight: 600;
  }

  #header.home #header-subtitle {
    color: #eaeaea;
  }

  #header.home .menu-btn .icon-bar {
    background: white !important;
  }

  #header.menu-open .menu-btn .icon-bar {
    background: black !important;
  }

  #header.menu-open #header-subtitle {
    color: darkgray;
  }

  #header-title-label {
    font-size: 1.8em;
  }

  #header-nav {
    height: 100%;
    width: 34%;
    text-align: center;
    flex: 1 1 34%;
    display: flex;
  }

  #header-nav-list {
    display: flex;
    flex-direction: row;
    margin: auto;
  }

  .header-nav-item {
    margin: 0 0.7vw;
    font-size: 0.85rem;
    letter-spacing: 0.05rem;
  }

  .header-nav-item a {
    padding-bottom: 1px;
    border-bottom: 0 solid transparent;
    transition: opacity 0.2s;
    opacity: 1;
  }

  .header-menu-nav-item a {
    transition: opacity 0.2s;
    opacity: 1;
  }

  .header-nav-item a:hover, .header-menu-nav-item a:hover {
    opacity: 0.5;
  }

  a.current-nav {
    border-bottom: 1px solid #2c3e50;
  }

  #header-actions-wrapper {
    width: 33%;
    display: flex;
  }

  #header-actions {
    margin: auto 0 auto auto;
  }

  #header-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    user-select: none;
    text-align: center;
    pointer-events: auto;
    color: black !important;
  }

  #header-menu-btn {
    display: none;
    visibility: hidden;
    margin: auto 0;
  }

  #header-menu-nav-list {
    position: relative;
    flex-grow: 1;
    width: 100%;
    background-color: white;
  }

  #header-menu-nav-folder {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
  }

  #header-menu-nav-folder-content {
    position: relative;
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    flex-direction: column;
  }

  .header-menu-nav-item {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 10px;
    font-size: 2rem;
    letter-spacing: 0.05rem;
  }

  #header-menu-actions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px 30px 20px;
    flex-grow: 0;
    flex-shrink: 1;
  }

  @media only screen and (max-width: 800px) {
    #header-nav, #header-actions-wrapper {
      display: none;
    }

    #header-menu-btn {
      display: block;
      visibility: visible;
    }
  }
</style>